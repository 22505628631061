
@font-face {
  font-family: 'Poppins';
  src: url('../src/components/style/Poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'PoppinsLight';
  src: url('../src/components/style/Poppins/Poppins-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html {
  font-size: 18px;
}

body {
  margin: 0;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F2F2F2 !important;
  height: 100vh;
  font-weight: 300;
  color: #525251;
}

label {
  color: #3a3a3a;
  font-weight: 500;
}

p {
  font-size: 1rem;
  color: #525251;
}

a {
  font-size: 1rem;
}

code {
  font-family: PoppinsLight sans-serif;
}

.PhoneInputInput:focus {

  border-color:#4c1986;
  outline: none; 
  /* border: 1px solid #4C1986; */
  border-width:1px;
  /* color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0; */
  /* box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); */
}

.ant-tooltip-arrow::after {
  border: 1px solid gray;
}

@media screen and (max-width: 560px) {
  html {
    font-size: 12px;
  }
}


@media screen and (min-width: 2800px) {
  html {
    font-size: 28px;
  }
}

@media screen and (max-width: 1800px) {
  html {
    font-size: 18px;
  }
}

@media screen and (max-width: 920px) {
  html {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  html {
    font-size: 12px;
  }
}


@media screen and (max-width: 360px) {
  html {
    font-size: 10px;
  }
}
