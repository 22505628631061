.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ct_custom_btn img {
  width: 25px;
  height: 25px;
  object-fit: contain;
  margin-right: 15px;
}
a.ct_custom_btn {
  border: 1px solid #c8d0d4;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fff;
  font-weight: 600;
  height: 48px;
  margin-bottom: 24px;
  padding: 8px 16px;
  -webkit-box-shadow: 0 1px 10px 0 rgba(0,0,0,.15);
  box-shadow: 0 1px 10px 0 rgba(0,0,0,.15);
  color: #333;
  text-decoration: none;
}
.ct_header h4 {
  margin-bottom: 15px;
  font-weight: 700;
}
.ct_header{
  margin-bottom: 30px;
}
.ct_header p {
  font-weight: 500;
  font-size: 14px;
}
.ct_right_form input {
  border: 1px solid #c8d0d4;
  min-height: 48px;
  max-width: none;
  border-radius: 0px;
}
.ct_right_form .ct_label {
  position: absolute;
  top: -10px;
  left: 10px;
  display: inline-block;
  padding: 0 3px;
  font-size: .875rem;
  z-index: 3;
  width: auto;
  color: #5e5b5d;
}
.ct_right_form .ct_label:after{
  content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    height: 12px;
    width: 100%;
    z-index: -1  !important;
}
.ct_right_form .form-group {
  position: relative;
  margin-bottom: 30px;
}
.ct_right_form h5 ,.ct_left_form h5{
  margin-bottom: 1.5rem;
}
.ct_check_terms label{
  font-size: 14px;
}
a {
  color: #4c1986;
  text-decoration: none;
}
a:hover{
  text-decoration: underline;
}
.form-footer  a{
  color: hsl(212, 70%, 54%);
  font-size: 14px;
  text-decoration: none;
}
.form-footer  a:hover{
  text-decoration: underline;
}
.form-footer p{
  color: #282c34;
  font-size: 14px;
}
.form-footer {
  font-size: 14px;
}
.submitDisabled{
  background-color: #dae1e7 !important;
    -webkit-transition-duration: 50ms;
    transition-duration: 50ms;
    cursor: not-allowed;
}
.ct_submit_btn:hover{
  background-color: #4c1986dd;
  color: #fff;
}
.ct_submit_btn {
  background-color: #4C1986;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  display: inline-block;
  padding: 8px 22px;
  font-size: 1.125rem;
  min-width: 115px;
  border-radius: 4px;
  font-size: 16px;
  border-radius: 4px !important;
  border: none;
}
.ct_header a {
  color: #4C1986;
  text-decoration: none;
}
.ct_header a:hover {
  color: #4C1986;
  text-decoration: underline;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.ct_padd_60{
  padding: 60px;
}

.ct_padd_30 {
  padding: 60px;
}

.ct_font_28 {
  font-size: 28px;
  font-weight: 700;
}

.ct_flex_box {
  display: flex;
  align-items: center;
  gap: 15px;
}

.ct_font_600{
  font-weight: 600;
}
.ct_payment_item {
  border: 1px solid #ccc;
  padding: 15px;
  box-shadow: 0px 10px 25px rgba(0,0,0,0.09);
  border-radius: 5px;
}
.ct_payment_item + .ct_payment_item{
  margin-top: 15px;
}
.ct_pay_btn button {
  width: 100%;
  /* padding: 10px; */
  border: none;
  background-color: #f5f5f5;
  border-radius: 5px;
}
#resendOTP {
  color: hsl(212, 70%, 54%);
  font-size: 16px;
  text-decoration:solid;
  border:none;
  background-color: transparent;
}
#continue_dashboard {
  background-color: #3e9c46;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    display: inline-block;
    padding: 8px 22px;
    font-size: 1.125rem;
    min-width: 115px;
    border-radius: 4px;
    font-size: 16px;
    border-radius: 4px !important;
    border: none;
}
.ct_2_grid {
  display: flex;
  align-items: center;
  gap: 10px;
}
.ct_pay_icon h5 {
  text-wrap: nowrap;
  font-weight: 600;
}

.ct_pay_btn button img {
  height: 48px;
  object-fit: contain;
}

.ct_credit_btn img {
  width: 20px;
  margin-right: 5px;
}

.ct_max_65{
  max-width: 65rem;
}

.label-float.ct_input_top input+label, .label-float.ct_input_top input:not(:placeholder-shown)+label{
top: 0px;
font-size: 13px;
    top: 0;
}
.ct_input_top select {
  border: 1px solid lightgrey;
  border-radius: 5px;
  outline: none;
  min-width: 100%;
  padding: 10px 20px;
  font-size: 16px;
  transition: all .1s linear;
  -webkit-transition: all .1s linear;
  -moz-transition: all .1s linear;
  /* -webkit-appearance: none; */
}
.ct_input_top select+label, .ct_input_top select:not(:placeholder-shown)+label{
  top: 0px;
  font-size: 13px;
      top: 0;
  }

  .ct_lock_icon {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
}

  .ct_verify_num {
    text-align: left;
}

.ct_agreement_cnt p {
  text-align: left;
}
.ct_checkbox_privacy input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

.ct_flex_2_col{
  display: flex;
  align-items: center;
  gap: 15px;
}

.ct_black_btn{
  background-color: #000 !important;
}

.ct_yellow_bg{
  background-color: #fdc43a!important;
}

.ct_credit_btn{
  background-color: #3591dc !important;
  color: #fff;
}
.ct_credit_btn img{
  filter: invert(1);
}