.root {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 3rem 0;
}

.container {
  background-color: #fff;
  padding: 2rem;
  border-width: 3px;
  border-color: #a19b9b;
  border-style: solid;
  border-radius: 1.5rem;
}

.label {
  position: relative;
  display: flex;
  padding: 0;
  color: #5e5b5d;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.group {
  position: relative;
  margin-bottom: 1.5rem;
}

.submitBtn {
  background-color: #4c1986;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  display: inline-block;
  padding: 8px 22px;
  font-size: 1.125rem;
  min-width: 115px;
  border-radius: 4px;
  font-size: 1rem;
  border-radius: 12px !important;
  border: none;
  margin-bottom: 1.5rem;

  &:hover {
    background-color: #4c19869c;
    color: #fff;
  }
}

.submitDisabled {
  background-color: #dae1e7;
  -webkit-transition-duration: 50ms;
  transition-duration: 50ms;
  cursor: not-allowed;
}

.logo {
  width: 40%;
  margin-bottom: 2rem;
}

.helperText {
  color: red;
  position: relative;
  display: flex;
  padding: 0;
  font-size: 0.9rem;
  margin-bottom: 0rem;
}
