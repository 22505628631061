.container {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 1rem;
}

.content {
  background-color: #fff;
  border: 3px solid #a19b9b;
  border-radius: 24px;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 42rem;
  min-height: 90vh;
  p {
    font-size: 1.44rem;
    color:#525251;
    font-weight: 400;
  }
  a {
    font-size: 1.44rem;
  }
  h3 {
    font-size: 2.3rem;
     color: #3A3A3A;
     text-align: center;
  }
  h4{
    font-size: 2.3rem;
    color: #3A3A3A;
    text-align: center;
  }
  button {
    font-size: 1.44rem;
  }
  footer {
    font-size:1rem;
    color:#525251;
  }
 
}





@media screen and (max-width: 720px) {
    .form {
      min-height: 75vh;
      button {
        font-size: 1.2rem;
      }
    }
  }
  

  @media screen and (max-width: 640px) {
  .container {
 
    p{
      font-size: 2rem;
    }
     h4{
    font-size: 1.5rem;
    color: #3A3A3A;
  }
   h3 {
    font-size: 1.5rem;
     color: #3A3A3A;
  }
  }


  .form {
    min-height: 70vh;
       width: 30rem;
    p{
      font-size: 1.2rem;
    }
  }
  
}
  
  
  @media screen and (max-width: 560px) {

    .container {
    margin: 0;
    width: 100%;
  }


  .form {
      width: 20rem;
    min-height: 30vh;
    p {
      font-size: 0.9rem;
    }
    a {
      font-size: 0.9rem;
    }
    h3 {
      font-size: 1.1rem;
    }
     h4 {
      font-size: 1.1rem;
    }
    .emailIcon{
    height:4rem;
  }
     button {
    font-size: 1rem;
  }
  }
    .form {
      min-height: 30vh;
      h3 {
        font-size: 1.1rem;
      }
    }
  }
  
  @media screen and (max-width: 360px) {

  .form {
    padding: 3rem 1rem;
    p{
      font-size: 0.9rem;
    }
    h4{
      font-size:1.1rem ;
    }
    
    .emailIcon{
    height:3.5rem;
  }
  }
  .emailIcon{
    height:3.5rem;
  }
  .subHeading {
    font-size: 0.9rem;
    margin-top: 2rem;
  }

  }
  