@import '../theme.scss';
@import './base_card.module.scss';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  align-items: center;
}

.banner {
  width: 100%;
  height: auto;
}

.emailIcon {
  height: 7.2rem;
  text-align: center;
  width: auto;
  margin-bottom: 1.6rem;
}

.logo {
  height: 4.2rem;
  width: auto;
  margin-bottom: 2rem;
}

// .title {
//   margin-bottom: 2rem;
// }

.subHeading {
  color: #525251;
  font-size: '1.44rem';
  text-align: center;
}

@media screen and (max-width: 720px) {
  .form {
    min-height: 75vh;
  }
  .container {
    width: 30rem;
    p {
      margin-top: 1rem;
    }
    h4 {
      font-size: 1.8rem;
      color: #3a3a3a;
    }
    h3 {
      font-size: 1.8rem;
      color: #3a3a3a;
    }
    button {
      font-size: 1.22rem;
    }
  }
}

@media screen and (max-width: 560px) {
  .root {
    margin: 0 2rem;
  }

  .logo {
    height: 2.5rem;
  }

  .title {
    color: $text-color;
    font-weight: 500;
  }
}

@media screen and (max-width: 360px) {
  .root {
    margin: 0 1rem;
  }
}
