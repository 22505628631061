@import '../../../theme.scss';

.root {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    align-items: center;
}

.container {
    background-color: #fff;
    width: 45%;
    padding: 2rem;
    border-width: 3px;
    border-color: #A19B9B;
    border-style: solid;
    border-radius: 1.5rem;
}

.title {
    h4 {
        font-size: 1.75rem;
        margin-bottom: 0;
    }
}

.logo {
    width: 40%;
}

.otpField {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 2rem;
    margin-bottom: 2rem;
}


.submitBtn:hover{
    background-color: #4c19869c;
    color: #fff;
  }
  .submitBtn {
    background-color: #4C1986;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    display: inline-block;
    padding: 8px 22px;
    font-size: 1.125rem;
    min-width: 115px;
    border-radius: 4px;
    font-size: 16px;
    border-radius: 12px !important;
    border: none;
  }

  .resend {
    color: $primary-color;
    text-decoration: underline;
    cursor: pointer;
  }

  .login {
    color: $primary-color !important;
  }