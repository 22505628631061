@import "../../style/form.scss";

.logo {
  width: 40%;
  margin-bottom: 2rem;
}

.title {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.form {
    & > div {
        margin-bottom: 1.5rem;
    }
}