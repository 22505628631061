@import '../theme.scss';
@import './base_card.module.scss';

.title {
  font-size: 1.8rem;
  font-weight: 600;
  color: $primary-color;
  margin-bottom: 1.2rem;
}

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  align-items: center;
}

.banner {
  width: 45%;
  height: auto;
  margin-right: 1rem;
}

.logo {
  height: 4rem;
  width: auto;
  margin-bottom: 0.75rem;
}

@media screen and (max-width: 560px) {
  .root {
    margin: 0 2rem;
  }

  .logo {
    height: 3rem;
    width: auto;
  }

  .title {
    color: $text-color;
    font-weight: 500;
  }
}

@media screen and (max-width: 360px) {
  .root {
    margin: 0 1rem;
  }
}
