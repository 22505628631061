.emailSentSubHeading {
  color: #525251;
  font-size: 1.44rem;
  text-align: center;
}

.emailSentFooterClass {
  color: #525251;
  font-size: 1rem;
  text-align: center;
  margin: 2rem;
}

@media screen and (max-width: 360px) {
  .emailSentFooterClass {
    font-size: 0.9rem;
  }
}
