@import '../theme.scss';

.group {
  position: relative;
}

.label {
  position: relative;
  display: flex;
  padding: 0;
  color: $subject-color;

  font-size: 1rem;
  margin-bottom: 0.25rem;
  align-items: center;
  font-weight: 500;
}

.helperText {
  color: red;
  position: relative;
  display: flex;
  padding: 0;
  font-size: 0.9rem;
  margin-bottom: 0;
}

.tooltipIcon {
  margin-left: 0.5rem;
  height: 1rem;
  width: auto;
}

.formControl {
  border-radius: 8px;
  border: 2px solid #66666659;
  color: #52525199;
  font-weight: 400;
  font-size: 0.88rem;
  padding: 0.5rem;
}

.form-control::placeholder {
  color: #52525199;
  font-weight: 400;
  font-size: 0.88rem;
}

@media screen and (max-width: 720px) {
  .formControl {
    width: 100%;
    font-size: 0.66rem; // Smaller font size for small screens
  }
}

@media screen and (max-width: 480px) {
  .formControl {
    font-size: 8px; // Smaller font size for small screens
  }
}
