@import '../theme.scss';
@import './textfield.module.scss';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  cursor: pointer;
  height: 1rem;
  width: auto;
}

.policyList {
  margin: 0;
  & li {
    font-size: 0.8rem;
    margin: 0.2rem;
  }
}

.tooltipIcon {
  margin-left: 0.5rem;
  height: 1rem;
  width: auto;
}
