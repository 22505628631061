@import '../theme.scss';

.submitBtn {
  background-color: $primary-color;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  display: inline-block;
  padding: 8px 22px;
  font-size: 1.44rem;
  min-width: 115px;
  border-radius: 8px;
  border: none;

  &:hover {
    background-color: $primary-color;
    color: #fff;
  }
}

.link {
  display: inline;
  color: $primary-color;
  text-decoration: underline;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.link.disabled {
  background-color: transparent;
  color: #dae1e7;
  border: none;
  &:hover {
    background-color: transparent;
    border: none;
  }
}

.outlined {
  border-width: 1px;
  border-style: solid;
  padding: 0.5rem;
  border-radius: 2rem;
  width: 100%;
  background-color: transparent;
  color: #3a3a3a;
  font-size: 1rem;
  font-weight: 600;

  &:hover {
    border-color: $primary-color;
    color: $primary-color;
  }
}

.disabled {
  background-color: #dae1e7;
  -webkit-transition-duration: 50ms;
  transition-duration: 50ms;
  cursor: not-allowed;

  &:hover {
    background-color: #dae1e7;
    -webkit-transition-duration: 50ms;
    transition-duration: 50ms;
    cursor: not-allowed;
  }
}
