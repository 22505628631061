p.forgotPasswordText {
  color: #525251;
  font-size: 1.22rem;
  text-align: center;
  a {
    font-size: 1.22rem;
  }
}

.forgotPasswordemailIcon {
  height: 11.1rem;
  text-align: center;
  width: 'auto';
  margin: '16rem';
}

p.forgotPasswordEmailSentText {
  color: #525251;
  font-size: 1.44rem;
  margin-top: 1rem;
  text-align: center;
}

@media screen and (max-width: 720px) {
  p.forgotPasswordEmailSentText {
    font-size: 0.8rem;
  }

  .forgotPasswordemailIcon {
    height: 7.2rem;
  }

  p.forgotPasswordText {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 560px) {
  .forgotPasswordemailIcon {
    height: 4rem;
  }
}

@media screen and (max-width: 360px) {
  p.forgotPasswordEmailSentText {
    font-size: 0.9rem;
    margin-top: 2rem;
  }

  .forgotPasswordemailIcon {
    height: 3.5rem;
  }

  p.forgotPasswordText {
    font-size: 0.9rem;
    margin-top: 2rem;
  }
}
