.formFooter {
  margin-top: 2.5rem;
  & a {
    text-decoration: none;
  }
}

.forgotPassword {
  text-align: right;
  color: "#5e5b5d";
  text-decoration: "underline";
}

.form {
  width: 100%;
  & > * {
    margin-bottom: 1.5rem;
  }
}

.loginButton {
  position: relative;
  margin-bottom: 2.5rem;
}

.inputGroup {
  margin-bottom: 2.5rem;
  .group {
    margin-bottom: 8px;
  }

  & > * {
    margin-bottom: 1.5rem;
  }
}

.passwordField {
  margin-bottom: 8px;
}