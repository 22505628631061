@import '../../../theme.scss';

.phoneInput {
  border-radius: 0.75rem;
  & input {
    border: none;
    padding-left: 0.5rem;
  }
}

.nameInput {
  display: grid;
  grid-template-columns: 50% 50%;
  flex-grow: 1;
  div:first-child {
    margin-right: 1rem;
  }
}

.divider {
  margin: 0;
}

.terms {
  text-align: left;
  margin-bottom: 0.75rem;
  
  p {
    font-size: 0.9rem;
  }
  & a {
    color: $primary-color;
    text-decoration: underline;
    font-size: 0.9rem;
  }
}

.form {
  & > * {
    margin-bottom: 0.75rem;
  }
}

.formFooter {
  p {
    margin-bottom: 0.5rem;
  }
  & a {
    text-decoration: none;
  }
}

.inputField {
  margin-bottom: 0.75rem;
}
